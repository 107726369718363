import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NewsListPage from '../components/NewsComponents/NewsListPage';
import SEO from '../components/seo';

const propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

const defaultProps = {
  data: null,
  pageContext: {}
};

function AllNewsListPage({ data: queryResult, pageContext }) {
  const allNews = get(queryResult, 'allPrismicNewsItem.edges', []);
  const latestArticles = get(queryResult, 'latestArticles.edges', []);
  const { page_title, description } = queryResult.prismicNewsItemList.data;

  return (
    <NewsListPage
      title={page_title}
      description={description}
      type={queryResult.prismicNewsItemList.type}
      uid={queryResult.prismicNewsItemList.uid}
      newsItems={allNews}
      pagination={pageContext}
      latestArticles={latestArticles}
    />
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicNewsItemList.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query AllNewsListQuery($id: String!, $skip: Int!, $limit: Int!) {
    prismicNewsItemList(id: { eq: $id }) {
      uid
      type
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        page_title
        description {
          text
          richText
        }
      }
    }
    allPrismicNewsItem(
      sort: { fields: data___post_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          type
          uid
          ...NewsInfo
        }
      }
    }
    latestArticles: allPrismicNewsItem(
      sort: { fields: data___post_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
  }
`;

AllNewsListPage.propTypes = propTypes;
AllNewsListPage.defaultProps = defaultProps;

export default withPrismicPreview(AllNewsListPage);
